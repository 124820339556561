import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Skeleton, notification, Divider, Avatar, Switch, Select, Drawer } from 'antd';

import axios from "axios";
import InputMask from 'react-input-mask';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { UserOutlined, InboxOutlined } from '@ant-design/icons';
import uploadDocumentService from '../../../Helpers/uploadService'
import Loading from '../../../Helpers/Loading'

export class AddOrUpdateCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: props.id,


            eitems: [],
            bank: [],
            address: [],
            billing: [],


            currentFile: undefined,
            previewImage: undefined,
            progress: 0,
            message: "",
            imageInfos: [],
            ratiow: 450,
            ratioh: 450,
            minw: 450,
            minh: 450,
            setImgW: "",
            setImgH: "",
            x: "",
            y: "",
            imgLocation: "1",
            uploadStart: false,

            sex: "Mr",
            name: "",
            surname: "",
            title: "",
            avatar: "",

            selectedBank: [],
            selectedAddress: [],
            selectedBilling: [],
        }
        this.cropperRef = createRef(null);
    }





    selectFile = (event) => {

        if (event.target.files[0]) {
            const image = new Image();
            let fr = new FileReader();

            fr.onload = function () {
                if (fr !== null && typeof fr.result == "string") {
                    image.src = fr.result;
                } else {

                }
            };


            fr.readAsDataURL(event.target.files[0]);

            const _this = this
            image.onload = async function () {

                const orginalW = image.width


                if (image.width < 450 || image.height < 450) {

                    event.target.value = null


                    notification.error({
                        message: 'Upload Errror.',
                        description:
                            'Min 450x450 pixel.',

                    })

                    console.log("1");

                }  else {


                    console.log("3");

                    if (image.width > 1920) {
                        image.width = 1920
                        image.height = (orginalW / 1920) * image.height
                    }


                    _this.setState({
                        currentFile: event.target.files[0],
                        previewImage: URL.createObjectURL(event.target.files[0]),
                        progress: 0,
                        message: ""
                    });

                    event.target.value = null
                }
            };

        }


    }

    getDetailImg = (e) => {
        this.setState({
            setImgW: e.detail.width,
            setImgH: e.detail.height,
            x: e.detail.x,
            y: e.detail.y
        })
    }


    close = () => {

        this.setState({
            previewImage: undefined,
        })
    }


    setResize = () => {
        const data = {
            w: this.state.setImgW,
            h: this.state.setImgH,
            x: this.state.x,
            y: this.state.y,
            productId: this.props.productId,
            imgLocation: this.state.imgLocation
        }

        const formData = new FormData();
        formData.append("file", this.state.currentFile);
        formData.append("data", JSON.stringify(data));

        this.upload(formData);

    }

    upload(data) {
        this.setState({
            progress: 0,
            uploadStart: true
        });

        uploadDocumentService.upload(data, (event) => {
            this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
            });
        })
            .then((response) => {
                this.setState({
                    uploadStart: false,
                    currentFile: undefined,
                    previewImage: undefined,
                    progress: 0,
                    message: "",
                    imageInfos: [],
                });

                if (response.data.returns === true) {

                    this.setState({
                        avatar: response.data.data.raw_name + "-ResizeS" + response.data.data.file_ext

                    });

                }
            })
            .then((files) => {
                this.setState({
                    imageInfos: files.data,
                });
            })
            .catch((err) => {
                this.setState({
                    uploadStart: false,
                    currentFile: undefined,
                    previewImage: undefined,
                    progress: 0,
                    message: "Could not upload the image!",
                    imageInfos: [],
                });
            });
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



    getMemberInfo = () => {
        this.setState({
            loading: true,
        });

        axios.post(process.env.REACT_APP_ADDRESS + `Card/getMemberInfo`)
            .then(res => {
                const result = res.data;
                this.setState({
                    loading: false,
                    bank: result.bank,
                    address: result.address,
                    billing: result.billing,
                    eitems: result.eitems,
                }, () => {


                    this.getOldData()

                });
            });


    }


    getOldData = () => {
        if (this.props.id !== 0) {

            const data = {
                id: this.props.id
            }
            this.setState({
                loading: true,
            });

            axios.post(process.env.REACT_APP_ADDRESS + `Card/getItemById`, data)
                .then(res => {

                    const result = res.data;


                    this.setState({
                        
                        sex: result.data.sex,
                        name: result.data.name,
                        surname: result.data.surname,
                        title: result.data.title,
                        avatar: result.data.avatar,
                        selectedBank: result.data.selectedBank,
                        selectedAddress: result.data.selectedAddress,
                        selectedBilling: result.data.selectedBilling,

                    });

                    let newData = [];

                    this.state.eitems.forEach((item, index) => {
                        const obj = {
                            checked: result.data.eItemVal.filter(xs => xs.itemId === item.id)[0].checked,
                            icon: item.icon,
                            id: item.id,
                            keys: item.keys,
                            name: item.name,
                            status: item.status,
                            types: item.types,
                            value: result.data.eItemVal.filter(xs => xs.itemId === item.id)[0].itemValue
                        }

                        newData = [...newData, obj];
                    });

                
                    this.setState({
                        loading: false,
                        eitems:newData
                    });
                   


                });
        } else {
            this.setState({
                loading: false,


            });
        }
    }




    componentDidMount() {


        this.getMemberInfo();

    }





    onSubmit = (e) => {
        e.preventDefault();



        const data = {
            id: this.props.id,
            sex: this.state.sex,
            name: this.state.name,
            surname: this.state.surname,
            title: this.state.title,
            avatar: this.state.avatar,
            eitems: this.state.eitems,
            selectedBank: this.state.selectedBank,
            selectedAddress: this.state.selectedAddress,
            selectedBilling: this.state.selectedBilling,

        }



        axios.post(process.env.REACT_APP_ADDRESS + `Card/addOrUpdate`, data)
            .then(res => {
                this.setState({
                    loading: false,
                }, () => {
                    this.props.refresh();
                });

            });
    }


    updateItems = (i, va) => {
        console.log(i);
        this.setState({
            eitems: this.state.eitems.map((
                obj, index) => (index === i ? Object.assign(obj, {
                    value: va.target.value,
                }) : obj)
            )
        });
    }

    updateSwitch = (i, va) => {


        this.setState({
            eitems: this.state.eitems.map((
                obj, index) => (index === i ? Object.assign(obj, {
                    checked: va ? 1 : 0,
                }) : obj)
            )
        });

    }


    selectedBankFunction = (value) => {
        this.setState({
            selectedBank: value
        })
    }

    selectedAddressFunction = (value) => {
        this.setState({
            selectedAddress: value
        })
    }

    selectedBillingFunction = (value) => {
        this.setState({
            selectedBilling: value
        })
    }


    render() {

        console.log(this.state);


        return (
            <>

                {this.state.loading ? <Skeleton /> :
                    <>
                        <form onSubmit={this.onSubmit}>

                            <Divider orientation="left" orientationMargin="0">Personal Info</Divider>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <div className="form-floating">
                                        <select className="form-select" name="sex" id="sex" value={this.state.sex} onChange={this.onChange} aria-label="sex" required>
                                            <option value="Mr">Mr.</option>
                                            <option value="Ms">Ms.</option>

                                        </select>
                                        <label htmlFor="sex">T.</label>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="name" value={this.state.name} onChange={this.onChange} placeholder="Name" required className="form-control" id="name" />
                                        <label htmlFor="name">Name</label>
                                    </div>
                                </div>

                                <div className='col-md-5'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="surname" value={this.state.surname} onChange={this.onChange} placeholder="Surname" required className="form-control" id="surname" />
                                        <label htmlFor="surname">Surname</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="title" value={this.state.title} onChange={this.onChange} placeholder="title" required className="form-control" id="title" />
                                        <label htmlFor="title">Job Title</label>
                                    </div>
                                </div>
                            </div>

                            <Divider orientation="left" orientationMargin="0">Avatar</Divider>
                            <div className='row'>
                                <div className='col-md-3 text-center'>
                                    {this.state.avatar === "" ?
                                        <Avatar
                                            size={128}
                                            icon={<UserOutlined />}
                                        />
                                        :
                                        <Avatar
                                            size={128}
                                            src={process.env.REACT_APP_ADDRESS + "Assets/upload/" + this.state.avatar}
                                        />
                                    }
                                </div>
                                <div className='col-md-9'>

                                    <div className="file-upload" >
                                        <input type="file" accept="image/*" onChange={this.selectFile.bind(this)} />
                                        <div className="addImg">
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <Divider orientation="left" orientationMargin="0">E-Item</Divider>

                            {this.state.eitems.map((item, index) => (

                                <div className="row align-items-center mb-2" key={index}>
                                    <div className='col-md-1' >
                                        <div className='itemIcon' dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                    </div>


                                    <div className='col-md-2'>
                                        {item.name}
                                    </div>
                                    <div className='col-md-1'>
                                        <Switch onChange={this.updateSwitch.bind(this, index)} size="small" checked={item.checked === 1 ? true : false} />
                                    </div>
                                    <div className='col-md-8'>

                                        {(item.types === "phone" || item.types === "whatsapp") &&
                                            <InputMask value={item.value} onChange={this.updateItems.bind(this, index)} className='form-control form-control-sm' mask="+99 999 999 99 99" disabled={item.checked === 1 ? false : true} />
                                        }
                                        {item.types === "email" &&
                                            <input type="email" value={item.value} onChange={this.updateItems.bind(this, index)} maxLength={45} name="title" placeholder="E-mail" className="form-control form-control-sm" disabled={item.checked === 1 ? false : true} />
                                        }
                                        {item.types === "link" &&
                                            <input type="text" value={item.value} onChange={this.updateItems.bind(this, index)} name="title" placeholder={item.name + " Address"} className="form-control form-control-sm" disabled={item.checked === 1 ? false : true} />
                                        }
                                        {item.types === "location" &&
                                            <input type="text" value={item.value} onChange={this.updateItems.bind(this, index)} name="title" placeholder={item.name + " Address"} className="form-control form-control-sm" disabled={item.checked === 1 ? false : true} />
                                        }
                                        {item.types === "apps" &&
                                            <input type="text" value={item.value} onChange={this.updateItems.bind(this, index)} name="title" placeholder={item.name + " Address"} className="form-control form-control-sm" disabled={item.checked === 1 ? false : true} />
                                        }
                                    </div>
                                </div>
                            ))}

                            <Divider orientation="left" orientationMargin="0">Bank Information</Divider>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Select
                                        mode="multiple"
                                        value={this.state.bank.filter(item => this.state.selectedBank.includes(item.value))}
                                        style={{ width: '100%' }}
                                        placeholder="Please select bank"
                                        onChange={this.selectedBankFunction}
                                        options={this.state.bank}
                                    />
                                </div>
                            </div>
                            <Divider orientation="left" orientationMargin="0">Billing Information</Divider>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Select
                                        mode="multiple"
                                        value={this.state.billing.filter(item => this.state.selectedBilling.includes(item.value))}
                                        style={{ width: '100%' }}
                                        placeholder="Please select billing address"
                                        onChange={this.selectedBillingFunction}
                                        options={this.state.billing}
                                    />
                                </div>
                            </div>
                            <Divider orientation="left" orientationMargin="0">Contact Address</Divider>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Select
                                        mode="multiple"
                                        value={this.state.address.filter(item => this.state.selectedAddress.includes(item.value))}
                                        style={{ width: '100%' }}
                                        placeholder="Please select contact address"
                                        onChange={this.selectedAddressFunction}
                                        options={this.state.address}
                                    />
                                </div>
                            </div>
                            <div className='row mt-5 mb-2'>
                                <div className='col-md-12 text-end'>

                                    <button type="button" onClick={() => { this.props.close() }} className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary"> {this.props.id === 0 ? "Add" : "Update"}</button>
                                </div>
                            </div>
                        </form>
                        <Drawer title="Resize Photo"
                            placement="right"
                            onClose={this.close}
                            width={1024}
                            open={this.state.previewImage ? true : false}>
                            {this.state.previewImage &&
                                <>
                                    <div className='row mb-3'>
                                        <div className='col-md-12 text-end'>
                                            <Cropper
                                                src={this.state.previewImage}
                                                aspectRatio={false}
                                                minCropBoxWidth={this.state.minw}
                                                minCropBoxHeight={this.state.minh}
                                                guides={false}
                                                crop={this.getDetailImg.bind(this)}
                                                viewMode={2}
                                                ref={this.cropperRef}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-md-12 text-end'>

                                            <button type="button" onClick={this.close.bind(this)} className="btn btn-light me-2">Cancel</button>
                                            <button type="submit" className="btn btn-primary" onClick={this.setResize}> Resize Avatar</button>
                                        </div>
                                    </div>
                                    {this.state.uploadStart && <Loading />}
                                </>
                            }
                        </Drawer>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    Sites: state.Sites
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateCard)