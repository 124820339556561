import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivateRoute from '../../../Helpers/PrivateRoutes'
import { connect } from 'react-redux'
import Login from '../../Login/Component/Login';
import { v4 as uuid } from 'uuid';
import { storageGet, storageSet } from '../../../Helpers/Collector/StorageAction'
import { sessionGet, sessionSet } from '../../../Helpers/Collector/SessionAction'
import { getSites } from '../Reducer/AppReducer'
import Layouts from '../../Layout/Component/Layout';
import Dashboard from '../../Dashboard/Component/Dashboard';


import Card from '../../Card/Component/Card';
import Accounts from '../../Accounts/Component/Accounts';

import Bank from '../../Settings/Bank/Component/Bank';
import Accounting from '../../Settings/Accounting/Component/Accounting';

import Address from '../../Settings/Address/Component/Address';
import Company from '../../Settings/Company/Component/Company';

import Ebusiness from '../../Ebusiness/Component/Ebusiness';



export class App extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  init = () => {


    if (Object.keys(storageGet("clientId")).length === 0 && storageGet("clientId").constructor === Object) {
      const datas = {
        key: uuid()
      }
      storageSet("clientId", datas);
    }


    if (Object.keys(sessionGet("sessionId")).length === 0 && sessionGet("sessionId").constructor === Object) {

      const datas = {
        key: uuid()
      }
      sessionSet("sessionId", datas);

    }


    if (Object.keys(storageGet("clientId")).length !== 0 &&
      Object.keys(sessionGet("sessionId")).length !== 0
    ) {


      const datas = {
        clientId: storageGet("clientId"),
        sessionId: sessionGet("sessionId"),
        sId: Object.keys(storageGet("sId")).length !== 0 ? storageGet("sId") : "",
      }
      this.props.getSites(datas);

    }

  }


  componentDidMount() {
    this.init();
  }


  render() {

    const { loading } = this.props.Sites
    return (
      <>
        {!loading &&

          <BrowserRouter>

            <Routes>
              <Route exact element={<PrivateRoute />}>
                <Route exact path="/Dashboard" element={<Layouts><Dashboard /></Layouts>} />
                <Route exact path="/Card" element={<Layouts><Card /></Layouts>} />
                <Route exact path="/Accounts" element={<Layouts><Accounts /></Layouts>} />
                <Route exact path="/Settings/Bank" element={<Layouts><Bank /></Layouts>} />
                <Route exact path="/Settings/Accounting" element={<Layouts><Accounting /></Layouts>} />
                <Route exact path="/Settings/Address" element={<Layouts><Address /></Layouts>} />
                <Route exact path="/Settings/Company" element={<Layouts><Company /></Layouts>} />
              </Route>
              <Route path='/Login' element={<Login />} />
              <Route path='/' element={<Login />} />
              <Route path='/EB/*' element={<Ebusiness />} >
                <Route path=":id" element={<Ebusiness />} />
              </Route>
            </Routes>

          </BrowserRouter>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  Sites: state.Sites
})

const mapDispatchToProps = {
  getSites
}

export default connect(mapStateToProps, mapDispatchToProps)(App)


