import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from "axios";

import "../../../Assets/css/Vcard.css";

import { Drawer, Divider } from 'antd';


import VCard from 'vcard-creator'
import {
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    ViberShareButton,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon,
    ViberIcon,
} from "react-share";
import Qr from '../../../Helpers/Qr';




export class Vcard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            loading: true,
            data: [],
            eItems: [],
            menu: false,
            phone: "",
            email: "",
            billing: false,
            address: false,
            bank: false,

            billingList: [],
            addressList: [],
            bankList: [],
        }



    }


    componentDidMount() {

        const data = {
            id: this.state.id
        }

        axios.post(process.env.REACT_APP_ADDRESS + `Ebusiness/getVCard`, data)
            .then(res => {
                this.setState({
                    loading: false,
                    data: res.data.data,
                    billingList: res.data.data.billing,
                    addressList: res.data.data.address,
                    bankList: res.data.data.bank,

                    eItems: res.data.data.eItems,
                    phone: res.data.data.eItems.filter(item => item.name === 'Mobile Phone')[0].val,
                    email: res.data.data.eItems.filter(item => item.name === 'E-mail')[0].val
                });



            });



    }

    menu = () => {
        this.setState({
            menu: !this.state.menu
        })
    }

    checkKeys = (keys) => {


        if (keys in this.state.data.eItems) {
            return true
        } else {
            return false
        }


    }



    downloadVcard = () => {

        const data = this.state.data;
        let xd = {
            email: "",
            mobile: "",
            local: "",
            twitter: "",
            web: "",

        }


        if (data.eItems.filter(item => item.name === 'E-mail').length > 0) {
            xd["email"] = data.eItems.filter(item => item.name === 'E-mail')[0].val
        }


        if (data.eItems.filter(item => item.name === 'Mobile Phone').length > 0) {
            xd["mobile"] = data.eItems.filter(item => item.name === 'Mobile Phone')[0].val
        }

        if (data.eItems.filter(item => item.name === 'Local Phone').length > 0) {
            xd["local"] = data.eItems.filter(item => item.name === 'Local Phone')[0].val
        }

        if (data.eItems.filter(item => item.name === 'Twitter').length > 0) {
            xd["twitter"] = data.eItems.filter(item => item.name === 'Twitter')[0].val
        }

        if (data.eItems.filter(item => item.name === 'Web sites').length > 0) {
            xd["web"] = data.eItems.filter(item => item.name === 'Web sites')[0].val
        }









        const a = new VCard();
        const lastname = data.surname
        const firstname = data.name
        const additional = ''
        const prefix = ''
        const suffix = ''
        a
            // Add personal data
            .addName(lastname, firstname, additional, prefix, suffix)
            // Add work data
            .addPhoto(data.avatarBase64, 'JPEG')
            .addCompany(data.brandTitle)
            .addJobtitle(data.title)
            .addRole('')
            .addEmail(xd.email)
            .addPhoneNumber(xd.mobile, 'PREF;WORK')
            .addPhoneNumber(xd.local, 'WORK')
            .addSocial(xd.twitter, 'Twitter', '')
            .addURL(xd.web)

        const element = document.createElement("a");

        const file = new Blob([a], { type: "text/x-vCard;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = data.name + " " + data.surname + ".vcf";
        document.body.appendChild(element);
        element.click();
        // idCardBase64 = result;



    }



    openCloseBilling = () => {
        this.setState({
            billing: !this.state.billing
        })
    }

    openCloseAddress = () => {
        this.setState({
            address: !this.state.address
        })
    }

    openCloseBank = () => {
        this.setState({
            bank: !this.state.bank
        })
    }


    render() {


        return (
            <>

                {this.loading ? "" :

                    <div className='app-layout'>

                        <div className='app-header'>
                            <div className='light x1'></div>
                            <div className='light x2'></div>
                            <div className='light x3'></div>
                            <div className='light x4'></div>
                            <div className='light x5'></div>
                            <div className='light x6'></div>
                            <div className='light x7'></div>
                            <div className='light x8'></div>
                            <div className='light x9'></div>
                            <div className='logo'>
                                <img src={this.state.data.companyLogo} alt="logo" />
                            </div>
                        </div>

                        <div className='profiles'>
                            <div className='avatar'>
                                <img src={this.state.data.avatar} className="circle" alt="profile" />

                            </div>
                            <div className='profileInfo text-center'>
                                <div className='name'>
                                    {this.state.data.sex}. {this.state.data.name} {this.state.data.surname}
                                </div>
                                <div className='title'>
                                    {this.state.data.title}
                                </div>
                                <div className='companyTitle'>
                                    {this.state.data.companyTitle}
                                </div>
                            </div>
                        </div>
                        <div className='app-middle'>
                            <h4>Contacts</h4>
                            <ul className="list-group">
                                {this.state.eItems.filter(x => x.keys === 'contact').map((item, i) => (
                                    <li className='list-group-item items'>
                                        <a href={item.types === 'phone' ? "tel://" + item.val : item.types === "email" ? "mailto:" + item.val : item.types === "whatsapp" ? "https://wa.me/" + item.val : item.val} rel="noreferrer">
                                            <div className='icon' dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                            <div className='value'>{item.types === "location" ? "Open location " : item.val}</div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            {this.state.eItems.filter(x => x.keys === 'social').length > 0 &&
                                <>
                                    <h4 className='mt-4'>Social</h4>
                                    <ul className="list-group">
                                        {this.state.eItems.filter(x => x.keys === 'social').map((item, i) => (
                                            <li className='list-group-item items'>
                                                <a href={item.types === 'phone' ? "tel://" + item.val : item.types === "email" ? "mailto:" + item.val : item.val} rel="noreferrer">
                                                    <div className='icon' dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                                    <div className='value'>{item.val}</div>
                                                </a>

                                            </li>
                                        ))}
                                    </ul>
                                </>
                            }
                            {this.state.eItems.filter(x => x.keys === 'info').length > 0 &&
                                <>
                                    <h4 className='mt-4'>Info</h4>
                                    <ul className="list-group">
                                        {this.state.eItems.filter(x => x.keys === 'info').map((item, i) => (
                                            <li className='list-group-item items'>
                                                <a href={item.types === 'phone' ? "tel://" + item.val : item.types === "email" ? "mailto:" + item.val : item.val} rel="noreferrer">
                                                    <div className='icon' dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                                    <div className='value'>{item.types === "apps" ? "Download Apps " : item.val}</div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            }
                        </div>
                        <div className='app-footer'>
                            <div className='footer-menu'>
                                <button className="menu__button" onClick={this.menu.bind(this)}>
                                    <div>
                                        <div></div>
                                    </div>Menu
                                </button>
                                <section className="menu__body" hidden={!this.state.menu}>
                                    <div className="menu__header">
                                        <label>
                                            <div></div>
                                        </label>
                                        <p>Menu</p>
                                        <button title="Close" onClick={this.menu.bind(this)}>
                                            X
                                        </button>
                                    </div>
                                    <div className='menu__qr text-center mt-2'>
                                        <Qr val={window.location.href} size={150} logo={this.state.data.companyLogoBase64} />
                                    </div>

                                    <div className="menu__contact">
                                        <div className='buttonLink' onClick={this.openCloseBilling.bind(this)}>

                                            <i className="fa-solid fa-file-invoice-dollar"></i>
                                            <span>Billing</span>
                                        </div>
                                        <div className='buttonLink' onClick={this.openCloseAddress.bind(this)}>

                                            <i className="fa-solid fa-location-dot"></i>
                                            <span>Address</span>
                                        </div>
                                        <div className='buttonLink' onClick={this.openCloseBank.bind(this)}>
                                            <i className="fa-solid fa-money-check-dollar"></i>
                                            <span>Bank</span>
                                        </div>
                                    </div>
                                    <Divider style={{borderBottom:"0"}} orientation="center" orientationMargin="0">Share with</Divider>
                                    <div className='shareBlock'>
                                    
                                        <FacebookMessengerShareButton
                                            url={window.location.href}
                                            appId="521270401588372"
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookMessengerIcon size={32} round />
                                        </FacebookMessengerShareButton>
                                        <TelegramShareButton
                                            url={window.location.href}
                                            title={this.state.data.name + " " + this.state.data.surname + " Contact information "}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TelegramIcon size={32} round />
                                        </TelegramShareButton>
                                        <WhatsappShareButton
                                            url={window.location.href}
                                            title={this.state.data.name + " " + this.state.data.surname + " Contact information "}
                                            separator=":: "
                                            className="Demo__some-network__share-button"
                                        >
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                        <EmailShareButton
                                            url={window.location.href}
                                            title={this.state.data.name + " " + this.state.data.surname + " Contact information "}
                                            body={this.state.data.name + " " + this.state.data.surname + " Contact information "}
                                            className="Demo__some-network__share-button"
                                        >
                                            <EmailIcon size={32} round />
                                        </EmailShareButton>
                                        <ViberShareButton
                                            url={window.location.href}
                                            title={this.state.data.name + " " + this.state.data.surname + " Contact information "}
                                            className="Demo__some-network__share-button"
                                        >
                                            <ViberIcon size={32} round />
                                        </ViberShareButton>


                                    </div>
                                    <div className="menu__contact">
                                        <a className='buttonLink' href={"tel://" + this.state.phone}>
                                            <i className="fa-solid fa-phone"></i>
                                            <span>Call</span>
                                        </a>
                                        <a className='buttonLink' href={"mailto:" + this.state.email}>
                                            <i className="fa-solid fa-envelope"></i>
                                            <span>E-mail</span>

                                        </a>
                                        <div className='buttonLink' onClick={this.downloadVcard.bind(this)}>
                                            <i className="fa-solid fa-address-card"></i>
                                            <span>Add Contact</span>
                                        </div>

                                    </div>
                                </section>


                            </div>

                        </div>

                        <Drawer
                            title={"Billing Information"}
                            width={"100%"}
                            onClose={this.openCloseBilling.bind(this)}
                            open={this.state.billing}
                            bodyStyle={{ paddingBottom: 80, backgroundColor: 'rgb(239 239 244)' }}>
                            <>
                                {this.state.billingList.map((item, i) => (
                                    <>
                                        <Divider orientation="center">Billing {i + 1}</Divider>
                                        <ul className="list-group mb-3">

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Billing Name</b></div>
                                                <div className='value'>{item.billing_name}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Billing Address</b></div>
                                                <div className='value'>{item.billing_address}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Billing District</b></div>
                                                <div className='value'>{item.billing_district}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Billing City</b></div>
                                                <div className='value'>{item.billing_city}</div>
                                            </li>
                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Billing Country</b></div>
                                                <div className='value'>{item.billing_country}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Tax Administration</b></div>
                                                <div className='value'>{item.tax_administration}</div>
                                            </li>
                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Tax Number</b></div>
                                                <div className='value'>{item.tax_number}</div>
                                            </li>

                                        </ul>
                                    </>
                                ))}
                            </>
                        </Drawer>

                        <Drawer
                            title={"Address Information"}
                            width={"100%"}
                            onClose={this.openCloseAddress.bind(this)}
                            open={this.state.address}
                            bodyStyle={{ paddingBottom: 80, backgroundColor: 'rgb(239 239 244)' }}>
                            <>
                                {this.state.addressList.map((item, i) => (
                                    <>
                                        <Divider orientation="center">{item.title}</Divider>
                                        <ul className="list-group mb-3">

                                            <li className='list-group-item items'>
                                                <div className='icon'><b> Address</b></div>
                                                <div className='value'>{item.address}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>District</b></div>
                                                <div className='value'>{item.district}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>City</b></div>
                                                <div className='value'>{item.city}</div>
                                            </li>
                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Country</b></div>
                                                <div className='value'>{item.country}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Postcode</b></div>
                                                <div className='value'>{item.postcode}</div>
                                            </li>
                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Phone</b></div>
                                                <div className='value'>{item.phone}</div>
                                            </li>

                                        </ul>
                                    </>
                                ))}
                            </>
                        </Drawer>

                        <Drawer
                            title={"Bank Information"}
                            width={"100%"}
                            onClose={this.openCloseBank.bind(this)}
                            open={this.state.bank}
                            bodyStyle={{ paddingBottom: 80, backgroundColor: 'rgb(239 239 244)' }}>
                            <>
                                {this.state.bankList.map((item, i) => (
                                    <>
                                        <Divider orientation="center">{item.bankName}</Divider>
                                        <ul className="list-group mb-3">

                                            <li className='list-group-item items'>
                                                <div className='icon'><b> Bank Name</b></div>
                                                <div className='value'>{item.bankName}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Branch Name</b></div>
                                                <div className='value'>{item.branchName}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Acoounting Number</b></div>
                                                <div className='value'>{item.branchCode} - {item.accountNumber}</div>
                                            </li>
                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Iban</b></div>
                                                <div className='value'>{item.ibanNumber}</div>
                                            </li>

                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Currency</b></div>
                                                <div className='value'>{item.currency}</div>
                                            </li>
                                            <li className='list-group-item items'>
                                                <div className='icon'><b>Swift Code</b></div>
                                                <div className='value'>{item.swiftCode}</div>
                                            </li>

                                        </ul>
                                    </>
                                ))}
                            </>
                        </Drawer>


                    </div>
                }





            </>
        )
    }
}

const mapStateToProps = (state) => ({
    Sites: state.Sites
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Vcard)