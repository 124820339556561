import React, { Component } from 'react'
import { connect } from 'react-redux'
import { QRCode } from 'react-qrcode-logo';
export class Qr extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values:this.props.val,
            size:this.props.size,
            logo:this.props.logo
        }
    }


  render() {
    return (
        <QRCode
                                       
        eyeColor={
            [
                '#3f4296',  // top/left eye
                '#3f4296', // top/right eye
                '#3f4296',  // bottom/left eye
            ]
        }
        qrStyle={"dots"}
        eyeRadius={[
            { // top/left eye
                outer: [10, 10, 0, 10],
                inner: [0, 10, 10, 10],
            },
            [10, 10, 10, 0], // top/right eye
            [10, 0, 10, 10], // bottom/left
        ]}
        logoImage={this.state.logo}
        logoWidth={this.state.size}
        logoOpacity={0.15}
        size={this.state.size}
        value={this.state.values} />
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Qr)