import axios from "axios";

class uploadDocumentService {
    upload(file, onUploadProgress) {

        return axios.post(process.env.REACT_APP_ADDRESS + `SettingsCompany/uploadPhoto`, file, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });


    }


    getFiles(product) {

        return axios.post(process.env.REACT_APP_ADDRESS + `SettingsCompany/getPhoto`, product, {
            headers: {
                "Content-type": "application/json"
            },
        });
    }

    deleteImgs(id) {

        return axios.post(process.env.REACT_APP_ADDRESS + `/deleteDocument`, id, {
            headers: {
                "Content-type": "application/json"
            },
        });
    }

}

export default new uploadDocumentService();