import React from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";


import Vcard from './Vcard';


export const Ebusiness = (props) => {
    let { id } = useParams();

    return (
        <Vcard id={id}/>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Ebusiness)