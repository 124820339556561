import React from 'react'
import { connect } from 'react-redux'

export const LanguageSet = (props) => {
    
    
    const { translate, loading } = props.Sites;

    return (
        <>

            { !loading &&
                translate.languages[props.code]
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    Sites: state.Sites
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSet)