import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Skeleton, notification, Drawer } from 'antd';
import axios from "axios";
import uploadDocumentService from '../../../../Helpers/uploadService'
import Loading from '../../../../Helpers/Loading'

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { InboxOutlined } from '@ant-design/icons';



export class Bank extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      currentFile: undefined,
      previewImage: undefined,
      progress: 0,
      message: "",
      imageInfos: [],
      ratiow: 360,
      ratioh: 240,
      minw: 360,
      minh: 240,
      setImgW: "",
      setImgH: "",
      x: "",
      y: "",
      imgLocation: "1",
      uploadStart: false,

      companyTitle: "",
      brandTitle: "",
      language: "tr",
      logo: "",




    }
    this.cropperRef = createRef(null);
  }


  componentDidMount() {
    this.getDatas();
  }



  getDatas = () => {
    this.setState({
      loading: true,
    });
    axios.post(process.env.REACT_APP_ADDRESS + `SettingsCompany/getList`)
      .then(res => {


        this.setState({
          loading: false,
          companyTitle: res.data.data.companyTitle,
          brandTitle: res.data.data.brandTitle,
          language: res.data.data.language,
          logo: res.data.data.logo
        })

      });
  }


  selectFile = (event) => {

    if (event.target.files[0]) {
      const image = new Image();
      let fr = new FileReader();

      fr.onload = function () {
        if (fr !== null && typeof fr.result == "string") {
          image.src = fr.result;
        } else {

        }
      };


      fr.readAsDataURL(event.target.files[0]);

      const _this = this
      image.onload = async function () {

        const orginalW = image.width


        if (image.width < 1199 || image.height < 674) {

          event.target.value = null


          notification.error({
            message: 'Upload Errror.',
            description:
              'Min 1200x675 pixel.',

          })

          console.log("1");

        } else if (image.height > image.width) {

          notification.error({
            message: 'Logo size error.',
            description:
              'Only landscape should be used.',

          })


          event.target.value = null


        } else {


          console.log("3");

          if (image.width > 1920) {
            image.width = 1920
            image.height = (orginalW / 1920) * image.height
          }


          _this.setState({
            currentFile: event.target.files[0],
            previewImage: URL.createObjectURL(event.target.files[0]),
            progress: 0,
            message: ""
          });

          event.target.value = null
        }
      };

    }


  }

  getDetailImg = (e) => {
    this.setState({
      setImgW: e.detail.width,
      setImgH: e.detail.height,
      x: e.detail.x,
      y: e.detail.y
    })
  }


  close = () => {

    this.setState({
      previewImage: undefined,
    })
  }


  setResize = () => {
    const data = {
      w: this.state.setImgW,
      h: this.state.setImgH,
      x: this.state.x,
      y: this.state.y,
      productId: this.props.productId,
      imgLocation: this.state.imgLocation
    }

    const formData = new FormData();
    formData.append("file", this.state.currentFile);
    formData.append("data", JSON.stringify(data));

    this.upload(formData);

  }

  upload(data) {
    this.setState({
      progress: 0,
      uploadStart: true
    });

    uploadDocumentService.upload(data, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        this.setState({
          uploadStart: false,
          currentFile: undefined,
          previewImage: undefined,
          progress: 0,
          message: "",
          imageInfos: [],
        });

        if (response.data.returns === true) {

          this.setState({
            logo: response.data.data.raw_name + "-ResizeS" + response.data.data.file_ext

          });

        }
      })
      .then((files) => {
        this.setState({
          imageInfos: files.data,
        });
      })
      .catch((err) => {
        this.setState({
          uploadStart: false,
          currentFile: undefined,
          previewImage: undefined,
          progress: 0,
          message: "Could not upload the image!",
          imageInfos: [],
        });
      });
  }


  update = () => {

    const data = {
      companyTitle: this.state.companyTitle,
      brandTitle: this.state.brandTitle,
      language: this.state.language,
      logo: this.state.logo
    }


    this.setState({
      loading: true,
    });

    axios.post(process.env.REACT_APP_ADDRESS + `SettingsCompany/update`, data)
      .then(res => {
        this.setState({
          loading: false,
        });
        notification.success({
          message: 'Update complete.',
          description:
            'All data saved',

        })
      });
  }


  onChange = (e) => {

    this.setState({
      [e.target.name]: e.target.value
    });
  }



  render() {



    return (
      <>
        {this.state.loading ? <Skeleton /> :
          <>
            <div className='row'>
              <div className='col-md-12'>
                <Breadcrumb style={{ margin: '16px 0' }}>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item>Settings</Breadcrumb.Item>
                  <Breadcrumb.Item>Company </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className='col-md-12'>
                <div className="card">
                  <div className="card-body">
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        <h2>Company Information</h2>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-md-12'>
                        {this.state.loading ? <Skeleton /> :
                          <>
                            <form onSubmit={this.onSubmit}>

                              <div className='row'>
                                <div className='col-md-6'>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className="form-floating mb-3">
                                        <input type="text" name="companyTitle" value={this.state.companyTitle} onChange={this.onChange} placeholder="Company Title" required className="form-control" id="companyTitle" />
                                        <label htmlFor="companyTitle">Company Title</label>
                                      </div>
                                    </div>
                                    <div className='col-md-12'>
                                      <div className="form-floating mb-3">
                                        <input type="text" name="brandTitle" value={this.state.brandTitle} onChange={this.onChange} placeholder="Brand Title" required className="form-control" id="brandTitle" />
                                        <label htmlFor="brandTitle">Brand Title</label>
                                      </div>
                                    </div>

                                    <div className='col-md-12'>
                                      <div className="form-floating mb-3">
                                        <select className="form-select" name="language" id="language" value={this.state.language} onChange={this.onChange} aria-label="Language" required>
                                          <option value="tr">TR</option>
                                          <option value="en">En</option>
                                        </select>
                                        <label htmlFor="language">Language</label>
                                      </div>
                                    </div>
                                    <div className='col-md-12'>
                                      <button type="button" onClick={this.update.bind(this)} className="btn btn-primary"> Update</button>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-md-3'>
                                  <div className="file-upload" >
                                    <img className='prwImg' src={process.env.REACT_APP_ADDRESS + "Assets/upload/" + this.state.logo} alt="Logo" />
                                  </div>
                                </div>
                                <div className='col-md-3'>

                                  <div className="file-upload" >
                                    <input type="file" accept="image/*" onChange={this.selectFile.bind(this)} />
                                    <div className="addImg">
                                      <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                      </p>
                                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                      <p className="ant-upload-hint">
                                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                        band files
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <Drawer title="Resize Photo"
              placement="right"
              onClose={this.close}
              width={1024}
              open={this.state.previewImage ? true : false}>
              {this.state.previewImage &&
                <>
                  <div className='row mb-3'>
                    <div className='col-md-12 text-end'>
                      <Cropper
                        src={this.state.previewImage}
                        aspectRatio={false}
                        minCropBoxWidth={this.state.minw}
                        minCropBoxHeight={this.state.minh}
                        guides={false}
                        crop={this.getDetailImg.bind(this)}
                        viewMode={2}
                        ref={this.cropperRef}
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-md-12 text-end'>

                      <button type="button" onClick={this.close.bind(this)} className="btn btn-light me-2">Cancel</button>
                      <button type="submit" className="btn btn-primary" onClick={this.setResize}> Resize Logo</button>
                    </div>
                  </div>
                  {this.state.uploadStart && <Loading />}
                </>
              }
            </Drawer>
          </>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Bank)