import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Skeleton, notification } from 'antd';

import axios from "axios";
export class AddOrUpdateAccounting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: props.id,
            billing_name: "",
            billing_address: "",
            billing_district: "",
            billing_city: "",
            billing_country: "",
            tax_administration: "",
            tax_number: "",
        }
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.id !== 0) {

            const data = {
                id: this.props.id
            }
            this.setState({
                loading: true,
            });

            axios.post(process.env.REACT_APP_ADDRESS + `SettingsAccounting/getItemById`, data)
                .then(res => {

                    const result = res.data;
                    this.setState({
                        loading: false,
                        billing_name: result.data.billing_name,
                        billing_address: result.data.billing_address,
                        billing_district: result.data.billing_district,
                        billing_city: result.data.billing_city,
                        billing_country: result.data.billing_country,
                        tax_administration: result.data.tax_administration,
                        tax_number: result.data.tax_number

                    });

                });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        

        if (this.state.billing_name === "" ||
            this.state.billing_address === "" ||
            this.state.billing_district === "" ||
            this.state.billing_city === "" ||
            this.state.billing_country === "" ||
            this.state.tax_administration === "" ||
            this.state.tax_number === ""
        ) {
            notification.error({
                message: 'Input error.',
                description:
                    'Please check all input.',

            })

        } else {


            this.setState({
                loading: true,
            });


            const data = {
                id: this.props.id,
                billing_name: this.state.billing_name,
                billing_address: this.state.billing_address,
                billing_district: this.state.billing_district,
                billing_city: this.state.billing_city,
                billing_country: this.state.billing_country,
                tax_administration: this.state.tax_administration,
                tax_number: this.state.tax_number
            }



            axios.post(process.env.REACT_APP_ADDRESS + `SettingsAccounting/addOrUpdate`, data)
                .then(res => {
                    this.setState({
                        loading: false,
                    }, () => {
                        this.props.refresh();
                    });

                });

        }
    }



    render() {
        return (
            <>

                {this.state.loading ? <Skeleton /> :
                    <>
                        <form onSubmit={this.onSubmit}>


                            <div className='row'>
                                <div className='col'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="billing_name" value={this.state.billing_name} onChange={this.onChange} placeholder="Billing name" required className="form-control" id="billing_name" />
                                        <label htmlFor="billing_name">Billing name</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="billing_address" value={this.state.billing_address} onChange={this.onChange} placeholder="Billing Address" required className="form-control" id="billing_address" />
                                        <label htmlFor="billing_address">Billing Address</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="billing_district" value={this.state.billing_district} onChange={this.onChange} placeholder="Billing District" required className="form-control" id="billing_district" />
                                        <label htmlFor="billing_district">Billing District</label>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="billing_city" value={this.state.billing_city} onChange={this.onChange} placeholder="Billing City" required className="form-control" id="billing_city" />
                                        <label htmlFor="billing_city">Billing City</label>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="billing_country" onChange={this.onChange} value={this.state.billing_country} placeholder="Billing Country" required className="form-control" id="billing_country" />
                                        <label htmlFor="billing_country">Billing Country</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="tax_administration" value={this.state.tax_administration} onChange={this.onChange} placeholder="Tax administrator" className="form-control" id="tax_administration" />
                                        <label htmlFor="tax_administration">Tax administrator</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="tax_number" onChange={this.onChange} value={this.state.tax_number} placeholder="Tax number" required className="form-control" id="tax_number" />
                                        <label htmlFor="tax_number">Tax number</label>
                                    </div>
                                </div>


                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-12 text-end'>

                                    <button type="button" onClick={() => { this.props.close() }} className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary"> {this.props.id === 0 ? "Add" : "Update"}</button>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateAccounting)