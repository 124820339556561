import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from "axios";
import { Skeleton, notification } from 'antd';
import PasswordStrengthBar from 'react-password-strength-bar'

export class AccountEditOrAdd extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            username: "",
            password: "",
            name: "",
            surname: "",
            title: "",
            passwordLevel: 0
        }

    }

    componentDidMount() {

        if (this.props.id !== 0) {

            const data = {
                id: this.props.id
            }

            axios.post(process.env.REACT_APP_ADDRESS + `Accounts/getAccountById`, data)
                .then(res => {

                    const result = res.data;
                    this.setState({
                        loading: false,
                        username: result.data.username,
                        password: result.data.password,
                        name: result.data.name,
                        surname: result.data.surname,
                        title: result.data.title,

                    });

                });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    onSubmit = (e) => {

        e.preventDefault();
        const data = {
            id: this.props.id,
            username: this.state.username,
            password: this.state.password,
            name: this.state.name,
            surname: this.state.surname,
            title: this.state.title,
        }



        if (this.validateEmail(data.username) && data.password.length > 3 && data.name.length > 1) {


            if (data.id === 0 && this.state.passwordLevel < 2) {
                notification.error({
                    message: 'Input error.',
                    description:
                        'Please check password strength.',

                })
            } else {

                this.setState({
                    loading: true,
                });



                axios.post(process.env.REACT_APP_ADDRESS + `Accounts/addOrUpdateAccount`, data)
                    .then(res => {
                        this.setState({
                            loading: false,
                        });
                        if (res.data.status) {

                            this.props.refresh();

                        } else {
                            notification.error({
                                message: 'E-mail address error.',
                                description:
                                    'E-mail address is have in system. Please add a different e-mail address.',

                            });
                        }

                    });
            }
        } else {
            notification.error({
                message: 'Input error.',
                description:
                    'Please check email address, password, name or surname.',

            })
        }
    }


    validateEmail = (email) => {

        var re = /\S+@\S+\.\S+/;
        return re.test(email);

    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    passwordLevel = (e) => {


        this.setState({
            passwordLevel: e,
        });
    }

    render() {
        return (
            <>

                {this.state.loading ? <Skeleton /> :
                    <>
                        <form onSubmit={this.onSubmit}>
                            <div className='row mb-3'>
                                <div className='col-md-12'>
                                    <div className="form-floating">
                                        <input type="email" className="form-control" onChange={this.onChange} name="username" value={this.state.username} id="username" placeholder="Username" disabled={this.props.id === 0 ? false : true} />
                                        <label htmlFor="username">Username</label>
                                    </div>
                                </div>


                            </div>
                            {this.props.id === 0 &&

                                <div className='row mb-3'>
                                    <div className='col-md-12'>
                                        <div className="form-floating">
                                            <input type="password" className="form-control" onChange={this.onChange} name="password" value={this.state.password} id="password" placeholder="password" />
                                            <label htmlFor="password">Password</label>
                                        </div>
                                        <PasswordStrengthBar scoreWords={[
                                            "Weak",
                                            "Weak",
                                            "Medium",
                                            "Good",
                                            "Strong"]
                                        }
                                            shortScoreWord={"Too short"}
                                            onChangeScore={this.passwordLevel.bind(this)}
                                            password={this.state.password} />
                                    </div>
                                </div>
                            }
                            <div className='row mb-3'>
                                <div className='col-md-4'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" onChange={this.onChange} name="name" value={this.state.name} id="name" placeholder="Name" />
                                        <label htmlFor="name">Name</label>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" onChange={this.onChange} name="surname" value={this.state.surname} id="surname" placeholder="Surname" />
                                        <label htmlFor="surname">Surname</label>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" onChange={this.onChange} name="title" value={this.state.title} id="title" placeholder="Title" />
                                        <label htmlFor="title">Title</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-12 text-end'>

                                    <button type="button" onClick={() => { this.props.close() }} className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary"> {this.props.id === 0 ? "Add" : "Update"}</button>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditOrAdd)