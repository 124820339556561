import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import "../../../Assets/css/login.css"

import logo from '../../../Assets/img/logo.svg'
import Loading from '../../../Helpers/Loading';
import LanguageSet from '../../../Helpers/LanguageSet';
import { getLogin } from '../Reducer/LoginReducer'
import { Navigate } from 'react-router-dom'
export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
        }

    }

    onSubmit = (event) => {
        event.preventDefault();
        if (this.validateEmail(this.state.email) && this.state.password.length > 5) {
            this.props.getLogin(this.state)
        }
    }

    onChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })


    }





    validateEmail = (email) => {

        var re = /\S+@\S+\.\S+/;
        return re.test(email);

    }

    render() {


        const { loading } = this.props.Sites
        if (this.props.Login.auth) {
            return (
                <Navigate to="/Dashboard" />
            )
        } else {
            return (
                <>
                    {loading ? <Loading /> :
                        <div className='container-fluid logins'>

                            <div className='row align-items'>
                                <div className='col-xxl-9 col-xl-8 col-md-6 loginBg vh-100 align-items d-none d-md-block'>
                                    <div className='content'>


                                    </div>
                                </div>
                                <div className='col-xxl-3 col-xl-4 col-md-6'>
                                    <div className='contentLogin'>
                                        <div className='icon'><img src={logo} alt="icon" /></div>
                                        <h1 className='text-center mb-3 '><LanguageSet code="LOGIN_WELCOME" /></h1>
                                        <h5 className='text-center mb-5 '>Sign in</h5>
                                        <form onSubmit={this.onSubmit}>

                                            <div className='row'>
                                                <div className='col'>
                                                    <div className="form-floating mb-3">
                                                        <input type="email" name="email" onChange={this.onChange} placeholder={"Email"} required className="form-control" id="email" />
                                                        <label htmlFor="email">E mail address</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col'>
                                                    <div className="form-floating mb-3">
                                                        <input type="password" className="form-control" autoComplete="false" name="password" onChange={this.onChange} placeholder={"Password"} required id="password" />
                                                        <label htmlFor="password">Password</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col'>
                                                    <Link to="ForgetPassword">
                                                        <div className="forgetPassword">Forget password</div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col d-grid gap-2 text-center'>

                                                    <button type="submit" className="btn btn-primary btn-custom justify-content" >Login</button>

                                                </div>
                                            </div>
                                        </form>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className="orline">
                                                    <span>or</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col d-grid gap-2 text-center'>
                                                <Link to="Signup">
                                                    <button type="submit" className="btn btn-light w-100">Sign up</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    Sites: state.Sites,
    Login: state.Login
})

const mapDispatchToProps = {
    getLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)