import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Dropdown, Space, Table, Button, Drawer, Modal, Tag } from 'antd';
import axios from "axios";
import Loading from '../../../../Helpers/Loading';

import {
  DownOutlined,
  ExclamationCircleOutlined

} from '@ant-design/icons';
import AddOrUpdateAddress from './AddOrUpdateAddress';



export class Address extends Component {




  constructor(props) {
    super(props)


    const items = [
      {
        key: '1',
        label: 'Edit',
      },
      {
        key: '2',
        label: 'Enable / Disable',
      },

      {
        key: '3',
        label: "Delete",
        danger: true,
      },

    ];


    this.state = {
      id: 0,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0
      },
      filterTable: null,
      tableLoading: true,
      pageLoading: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'num',
          key: 'num',
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },

        {
          title: 'District',
          dataIndex: 'district',
          key: 'district',
        },
        {
          title: 'City',
          dataIndex: 'city',
          key: 'city',
        },
        {
          title: 'Country',
          dataIndex: 'country',
          key: 'country',
        },
        {
          title: 'Post code',
          dataIndex: 'postcode',
          key: 'postcode',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text) => (
            <>
              {text === 1 ?
                <Tag color="blue">Active</Tag>
                :
                <Tag>Passive</Tag>
              }
            </>
          )
        },
        {
          title: 'Actions',
          dataIndex: 'productCode',
          key: 'productCode',
          render: (text, record) => (
            <>
              <Dropdown
                trigger={["click"]}
                menu={{
                  items,
                  onClick: this.handleMenuClick.bind(this, record.id)
                }}>
                <Button >
                  <Space>
                    Action
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>


            </>
          )

        }
      ],
      tableData: [],
      open: false,


    }
  }

  handleMenuClick = (e, b, c) => {

    if (b.key === '3') {
      this.delete(e);
    }

    if (b.key === '2') {
      this.changeStatus(e);
    }
    if (b.key === "1") {
      this.setState({
        open: true,
        id: e
      })
    }




  }


  delete = (id) => {

    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure ?',
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: 'danger',
      onOk: (e) => {
        const data = {
          id: id
        }

        this.setState({
          pageLoading: true,
        });

        axios.post(process.env.REACT_APP_ADDRESS + `SettingsAddress/deleteItem`, data)
          .then(res => {
            Modal.destroyAll();
            this.setState({
              pageLoading: false,
            })
            this.getDatas();


          });
      }
    });
  }


  changeStatus = (id) => {

    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure ?',
      okText: 'Change Status',
      cancelText: 'Cancel',

      onOk: (e) => {
        const data = {
          id: id
        }

        this.setState({
          pageLoading: true,
        });

        axios.post(process.env.REACT_APP_ADDRESS + `SettingsAddress/changeStatus`, data)
          .then(res => {
            Modal.destroyAll();
            this.setState({
              pageLoading: false,
            })
            this.getDatas();


          });
      }
    });
  }


  handleTableChange = (a, b, c) => {

    this.setState({
      pagination: a,
      sorters: c

    }, () => {
      this.getDatas();
    })


  }


  componentDidMount() {
    this.getDatas();
  }

  getDatas = () => {
    const data = {
      pagination: this.state.pagination,
      sorters: this.state.sorters,
    }
    axios.post(process.env.REACT_APP_ADDRESS + `SettingsAddress/getList`, data)
      .then(res => {

        this.setState({
          tableData: res.data.data,
          tableLoading: false,
          pagination: {
            ...this.state.pagination, total: res.data.total
          }
        })
      });
  }



  close = () => {
    this.setState({
      open: false
    });
  }

  add = () => {
    this.setState({
      open: true,
      id: 0
    })
  }

  refresh = () => {
    this.setState({
      open: false,
      id: 0
    }, () => {
      this.getDatas();
    });

  }









  render() {
    return (
      <>
        {this.state.pageLoading && <Loading />}
        <div className='row'>
          <div className='col-md-12'>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Settings</Breadcrumb.Item>
              <Breadcrumb.Item>Address</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className='col-md-12'>
            <div className="card">
              <div className="card-body">
                <div className='row mb-3'>
                  <div className='col-md-12 text-end'>
                    <button type="button" onClick={this.add.bind(this)} class="btn btn-primary">Add new item</button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Table
                      columns={this.state.columns}
                      dataSource={this.state.filterTable == null ? this.state.tableData : this.state.filterTable}
                      rowKey={"key"}
                      scroll={{ x: 'auto' }}
                      pagination={this.state.pagination}
                      loading={this.state.tableLoading}
                      onChange={this.handleTableChange}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Drawer
          title={this.state.id === 0 ? "Create a new item" : "Update item"}
          width={720}
          onClose={this.close.bind(this)}
          open={this.state.open}
          bodyStyle={{ paddingBottom: 80 }}

        >
          {this.state.open &&
            <>
              <AddOrUpdateAddress refresh={this.refresh} close={this.close} id={this.state.id} />
            </>
          }
        </Drawer>

      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Address)