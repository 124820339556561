import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from "axios";
import { Skeleton, notification } from 'antd';
import PasswordStrengthBar from 'react-password-strength-bar'

export class PasswordReset extends Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            password: "",
            passwordLevel: 0
        }

    }

    componentDidMount() {


    }

    onSubmit = (e) => {

        e.preventDefault();
        const data = {
            id: this.props.id,
            password: this.state.password,

        }



        if (this.state.passwordLevel > 2) {

            this.setState({
                loading: true,
            });

            axios.post(process.env.REACT_APP_ADDRESS + `Accounts/updatePassword`, data)
                .then(res => {
                    this.setState({
                        loading: false,
                    });
                    if (res.data.status) {

                        this.props.close();

                    }

                });
        } else {
            notification.error({
                message: 'Input error.',
                description:
                    'Please check password strength.',

            })
        }


    }





    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    passwordLevel = (e) => {


        this.setState({
            passwordLevel: e,
        });
    }

    render() {
        return (
            <>

                {this.state.loading ? <Skeleton /> :
                    <>
                        <form onSubmit={this.onSubmit}>



                            <div className='row mb-3'>
                                <div className='col-md-12'>
                                    <div className="form-floating">
                                        <input type="password" className="form-control" onChange={this.onChange} name="password" value={this.state.password} id="password" placeholder="password" />
                                        <label htmlFor="password">Password</label>
                                    </div>
                                    <PasswordStrengthBar scoreWords={[
                                        "Weak",
                                        "Weak",
                                        "Medium",
                                        "Good",
                                        "Strong"]
                                    }
                                        shortScoreWord={"Too short"}
                                        onChangeScore={this.passwordLevel.bind(this)}
                                        password={this.state.password} />
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-12 text-end'>

                                    <button type="button" onClick={() => { this.props.close() }} className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary"> {this.props.id === 0 ? "Add" : "Update"}</button>
                                </div>
                            </div>


                        </form>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)