import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Skeleton, notification } from 'antd';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import axios from "axios";
export class AddOrUpdateAddress extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: props.id,
            title: "",
            address: "",
            district: "",
            city: "",
            country: "",
            postcode: "",
            phone: "",

        }
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        if (this.props.id !== 0) {

            const data = {
                id: this.props.id
            }
            this.setState({
                loading: true,
            });

            axios.post(process.env.REACT_APP_ADDRESS + `SettingsAddress/getItemById`, data)
                .then(res => {

                    const result = res.data;
                    this.setState({
                        loading: false,
                        title: result.data.title,
                        address: result.data.address,
                        district: result.data.district,
                        city: result.data.city,
                        country: result.data.country,
                        postcode: result.data.postcode,
                        phone: result.data.phone,


                    });

                });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    onSubmit = (e) => {
        e.preventDefault();



        if (this.state.title === "" ||
            this.state.address === "" ||
            this.state.district === "" ||
            this.state.city === "" ||
            this.state.country === ""

        ) {
            notification.error({
                message: 'Input error.',
                description:
                    'Please check all input.',

            })

        } else {


            this.setState({
                loading: true,
            });


            const data = {
                id: this.props.id,
                title: this.state.title,
                address: this.state.address,
                district: this.state.district,
                city: this.state.city,
                country: this.state.country,
                postcode: this.state.postcode,
                phone: this.state.phone,

            }



            axios.post(process.env.REACT_APP_ADDRESS + `SettingsAddress/addOrUpdate`, data)
                .then(res => {
                    this.setState({
                        loading: false,
                    }, () => {
                        this.props.refresh();
                    });

                });

        }
    }



    render() {
        return (
            <>

                {this.state.loading ? <Skeleton /> :
                    <>
                        <form onSubmit={this.onSubmit}>


                            <div className='row'>
                                <div className='col'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="title" value={this.state.title} onChange={this.onChange} placeholder="Title" required className="form-control" id="title" />
                                        <label htmlFor="title">Address Title</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="address" value={this.state.address} onChange={this.onChange} placeholder="Address" required className="form-control" id="address" />
                                        <label htmlFor="address">Address</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="district" value={this.state.district} onChange={this.onChange} placeholder="District" required className="form-control" id="district" />
                                        <label htmlFor="district">District</label>
                                    </div>
                                </div>

                                <div className='col-md-4'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="city" value={this.state.city} onChange={this.onChange} placeholder="City" required className="form-control" id="city" />
                                        <label htmlFor="city">City</label>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="country" onChange={this.onChange} value={this.state.country} placeholder="Country" required className="form-control" id="country" />
                                        <label htmlFor="country">Country</label>
                                    </div>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="postcode" value={this.state.postcode} onChange={this.onChange} placeholder="Post code" required className="form-control" id="postcode" />
                                        <label htmlFor="postcode">Post code</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-3 phone">
                                        <PhoneInput
                                            id="phoneNumber"
                                            placeholder="Mobile Phone *"
                                            country={'tr'}
                                            inputProps={{
                                                required: true,
                                            }}
                                            value={this.state.phone}
                                            onChange={phone => this.setState({
                                                phone: phone
                                            })}
                                        />
                                    </div>
                                </div>

                            </div>


                            <div className='row mb-3'>
                                <div className='col-md-12 text-end'>

                                    <button type="button" onClick={() => { this.props.close() }} className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary"> {this.props.id === 0 ? "Add" : "Update"}</button>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateAddress)