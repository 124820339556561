import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Skeleton, notification } from 'antd';
import * as ibantools from 'ibantools';
import axios from "axios";
export class AddOrUpdateBank extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: props.id,
            bankName: "",
            ibanNumber: "",
            currency: "TRY",
            eInvoice: "1",
            branchName: "",
            branchCode: "",
            accountNumber: "",
            swiftCode: "",

        }
    }


    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.name === "iban" ? e.target.value.toUpperCase() : e.target.value
        })
    }

    componentDidMount() {
        if (this.props.id !== 0) {

            const data = {
                id: this.props.id
            }
            this.setState({
                loading: true,
            });

            axios.post(process.env.REACT_APP_ADDRESS + `SettingsBank/getItemById`, data)
                .then(res => {

                    const result = res.data;
                    this.setState({
                        loading: false,
                        
                        bankName: result.data.bankName,
                        ibanNumber: result.data.ibanNumber,
                        currency: result.data.currency,
                        eInvoice: result.data.eInvoice,
                        branchName: result.data.branchName,
                        branchCode: result.data.branchCode,
                        accountNumber: result.data.accountNumber,
                        swiftCode: result.data.swiftCode,

                    });

                });
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        const ibans = ibantools.validateIBAN(this.state.ibanNumber);

        if (this.state.bankName === "" ||
            this.state.branchName === "" ||
            this.state.branchCode === "" ||
            this.state.accountNumber === ""
        ) {
            notification.error({
                message: 'Input error.',
                description:
                    'Please check Bank name, branc name, branc code, accounting number.',

            })

        } else if (ibans["valid"] === false) {
            notification.error({
                message: 'Input error.',
                description:
                    'Please check Iban number',

            })

        } else {


            this.setState({
                loading: true,
            });


            const data = {
                id: this.props.id,
                bankName: this.state.bankName,
                ibanNumber: this.state.ibanNumber,
                currency: this.state.currency,
                eInvoice: this.state.eInvoice,
                branchName: this.state.branchName,
                branchCode: this.state.branchCode,
                accountNumber: this.state.accountNumber,
                swiftCode: this.state.swiftCode,
            }



            axios.post(process.env.REACT_APP_ADDRESS + `SettingsBank/addOrUpdate`, data)
                .then(res => {
                    this.setState({
                        loading: false,
                    },()=> {
                        this.props.refresh();
                    });

                });

        }
    }



    render() {
        return (
            <>

                {this.state.loading ? <Skeleton /> :
                    <>
                        <form onSubmit={this.onSubmit}>


                            <div className='row'>
                                <div className='col'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="bankName" value={this.state.bankName} onChange={this.onChange} placeholder="Bank Name" required className="form-control" id="bankName" />
                                        <label htmlFor="bankName">Bank name</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="branchName" value={this.state.branchName} onChange={this.onChange} placeholder="Branch name" required className="form-control" id="branchName" />
                                        <label htmlFor="branchName">Branch name</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="branchCode" value={this.state.branchCode} onChange={this.onChange} placeholder="Branch code" required className="form-control" id="branchCode" />
                                        <label htmlFor="branchCode">Branch code</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="accountNumber" value={this.state.accountNumber} onChange={this.onChange} placeholder="Accounting number" required className="form-control" id="accountNumber" />
                                        <label htmlFor="accountNumber">Accounting number</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="ibanNumber" onChange={this.onChange} value={this.state.ibanNumber} placeholder="Iban" required className="form-control" id="iban" />
                                        <label htmlFor="iban">Iban Number</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <input type="text" name="swiftCode" value={this.state.swiftCode} onChange={this.onChange} placeholder="Swift code" className="form-control" id="swiftCode" />
                                        <label htmlFor="swiftCode">Swift code</label>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <select className="form-select" name="currency" id="currency" value={this.state.currency} onChange={this.onChange} aria-label="currency" required>
                                            <option value="TRY">TRY (₺)</option>
                                            <option value="USD">USD ($)</option>
                                            <option value="EUR">EUR (€)</option>
                                        </select>
                                        <label htmlFor="cashType">Currency</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-3">
                                        <select className="form-select" name="eInvoice" id="eInvoice" value={this.state.eInvoice} onChange={this.onChange} aria-label="E-invoice" required>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        <label htmlFor="eInvoice">E-invoice</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-12 text-end'>

                                    <button type="button" onClick={() => { this.props.close() }} className="btn btn-light me-2">Cancel</button>
                                    <button type="submit" className="btn btn-primary"> {this.props.id === 0 ? "Add" : "Update"}</button>
                                </div>
                            </div>
                        </form>
                    </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdateBank)